import React from 'react'

const ContactInfo = () => {
    return (
        <div className="contact-info-area pt-100 pb-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <div className="contact-info-box">
                            <div className="back-icon">
                                <i className='bx bx-map'></i>
                            </div>
                            <div className="icon">
                                <i className='bx bx-map'></i>
                            </div>
                            <h3>Our Address</h3>
                            <p>1309 Coffeen Avenue STE 1200, Sheridan, Wyoming 82801</p>
                            <p>Phone: <a href="tel:+13107765998">+1 (310) 776 5998</a></p>
                            <p>E-mail: <a href="mailto:info@koilitech.com">info@koilitech.com</a></p>
                        </div>
                    </div>
                    {/*<div className="col-lg-6 col-md-6">*/}
                    {/*    <div className="contact-info-box">*/}
                    {/*        <div className="back-icon">*/}
                    {/*            <i className='bx bx-map'></i>*/}
                    {/*        </div>*/}
                    {/*        <div className="icon">*/}
                    {/*            <i className='bx bx-map'></i>*/}
                    {/*        </div>*/}
                    {/*        <h3>Our Address (Nepal)</h3>*/}
                    {/*        <p>Satdobato - 15, Lalitpur, Nepal 44700</p>*/}
                    {/*        <p>Mobile: <a href="tel:+9779849308132">+977 (984) 930 8132</a></p>*/}
                    {/*        <p>E-mail: <a href="mailto:info@koilitech.com">info@koilitech.com</a></p>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                </div>
            </div>
        </div>
    )
}

export default ContactInfo
