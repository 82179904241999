import React, {useState} from 'react'
import axios from 'axios';
import starIcon from '../../assets/images/star-icon.png'
import contact from '../../assets/images/contact.png'
const FORM_URL = 'https://0ob1ojj9y9.execute-api.us-east-1.amazonaws.com/Prod/email';

const ContactForm = () => {

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [success, setSuccess] = useState(null);
    const [inProgress, setInProgress] = useState(false);

    const reset = () => { setName("");
        setPhone("");
        setEmail("");
        setMessage("");
    }

    const submit =  () => {
        setInProgress(true);
        const data = {name, email, phone, message};
        axios.post(FORM_URL, data)
            .then(function (response) {
                reset();
                setSuccess(true);
                setInProgress(false);
            })
            .catch(function (error) {
                setSuccess(false);
                setInProgress(false);
            });
    }

    return (
        <section className="contact-area pb-100">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="contact" /> 
                        Get in Touch
                    </span>
                    <h2>Ready to Get Started?</h2>
                    <p>Send us your query to help you better.</p>
                </div>

                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <div className="contact-image">
                            <img src={contact} alt="contact" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="contact-form">
                            <form id="contactForm" onSubmit={(e) => {
                                e.preventDefault();
                                submit();
                            }}>
                                <div className="row">
                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <input type="text" value={name} name="name" className="form-control" required placeholder="Your name" onChange={e => setName(e.target.value)}/>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <input type="email" name="email" value={email} className="form-control" required placeholder="Your email address" onChange={e => setEmail(e.target.value)}  />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <input type="text" name="phone_number" value={phone} className="form-control" required placeholder="Your phone number" onChange={e => setPhone(e.target.value)}  />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <textarea name="message" className="form-control" value={message} cols="30" rows="6" required placeholder="Write your message..." onChange={e => setMessage(e.target.value)}  />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <button disabled={inProgress} type="submit" className="default-btn">
                                            <i className="flaticon-tick"></i> 
                                            Send Message <span></span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                            <div style={ { marginTop: "10px" } }>
                            {success === true && <div className="alert alert-success" role="alert">
                                Message Sent.
                            </div>
                            }
                            {success === false && <div className="alert alert-danger" role="alert">
                                Something went wrong.
                            </div>
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactForm
